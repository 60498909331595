import React, { useEffect, useRef, useState, CSSProperties } from "react";
import mapboxgl from 'mapbox-gl';
import "mapbox-gl/dist/mapbox-gl.css";

const styles: CSSProperties = {
    width: "100%",
    height: "100%"
};


interface Props {
    longitude?: number
    latitude?: number
}

const MapboxGLMap = (props: Props) => {
    const [map, setMap] = useState<any>(null);
    const mapContainer = useRef<HTMLDivElement | null>(null);
    const [mapWidth, setMapWidth] = useState<number | null>(null)
    const [mapHeight, setMapHeight] = useState<number | null>(null)

    const mapboxKey = process.env.REACT_APP_MAPBOX_KEY
    useEffect(() => {
      window.addEventListener('resize', setMapSize)
      setMapSize()
    }, [])

    const setMapSize = () => {
        const m = document.getElementById('map-container')
        if (m) {
            setMapWidth(Math.round(m.getBoundingClientRect().width))
            setMapHeight(Math.round(m.getBoundingClientRect().height))
        }
    }
    // useEffect(() => {
    //     if (mapboxKey === undefined) {
    //         return
    //     }

    //     mapboxgl.accessToken = mapboxKey
    //     const initializeMap = ({ setMap, mapContainer }: any) => {
    //         const map = new mapboxgl.Map({
    //             container: mapContainer.current,
    //             style: "mapbox://styles/mdaguerre/ck8rtomvh0f2f1jo57e6batbm", // stylesheet location
    //             center: [props.longitude!, props.latitude!],
    //             zoom: 13
    //         });

    //         map.on("load", () => {
    //             setMap(map);
    //             map.resize();
    //         });

    //         map.scrollZoom.disable();
    //         map.addControl(new mapboxgl.NavigationControl());

    //         var el = document.createElement('div');
    //         el.className = 'marker';

    //         // make a marker for each feature and add to the map
    //         new mapboxgl.Marker(el)
    //             .setLngLat([props.longitude!, props.latitude!])
    //             .addTo(map);
    //     };

    //     if (!map) initializeMap({ setMap, mapContainer });
    // }, [props.latitude, props.longitude]);

    // useEffect(() =>{
    //     if(!map){
    //         return
    //     }

    //     console.log("LAT LNG CHANGED")

    //     var el = document.createElement('div');
    //     el.className = 'marker';

    //     // make a marker for each feature and add to the map
    //     new mapboxgl.Marker(el)
    //         .setLngLat([props.longitude!, props.latitude!])
    //         .addTo(map);

    //     map.flyTo({
    //         center:[props.longitude!, props.latitude!],
    //         zoom: 13
    //     })
    // }, [props.latitude, props.longitude])

    return (
        <>
        {/* <div ref={el => (mapContainer.current = el)} style={styles} /> */}
        {(mapWidth && mapHeight) &&
        <img alt='static Mapbox map of the San Francisco bay area' src={`https://api.mapbox.com/styles/v1/mapbox/light-v10/static/url-https%3A%2F%2Flistings.archilogic.com%2Fimg%2Fmarker.png(${props.longitude},${props.latitude})/${props.longitude},${props.latitude},14,0.00,0.00/${mapWidth}x${mapHeight}?access_token=${mapboxKey}&logo=false`} style={{ height: `${mapHeight}px`, width: `${mapWidth}px` }} />
        }
        </>
    );
};

export default MapboxGLMap;