import axios from 'axios'
import { message } from 'antd'

export const GET_FLOOR = 'floor/GET_FLOOR'
export const GET_FLOOR_CUSTOM_FIELD = 'floor/GET_FLOOR_CUSTOM_FIELD'
export const UPDATE_FLOOR = 'floor/UPDATE_FLOOR'
export const SHOW_EDIT_MODAL = 'floor/SHOW_EDIT_MODAL'
export const HIDE_EDIT_MODAL = 'floor/HIDE_EDIT_MODAL'
export const UPDATE_METRICS = 'floor/UPDATE_METRICS'
export const UPDATE_ASSET_METRICS = 'floor/UPDATE_ASSET_METRICS'
export const SET_SCENE_ID = 'floor/SET_SCENE_ID'
export const FETCHING_FLOOR = 'floor/FETCHING_FLOOR'
export const SAVING_FLOOR = 'floor/SAVING_FLOOR'
export const SAVING_FLOOR_COMPLETE = 'floor/SAVING_FLOOR_COMPLETE'
export const FETCHING_METRICS = 'floor/FETCHING_METRICS'
export const FETCHING_ASSET_METRICS = 'floor/FETCHING_ASSET_METRICS'
export const SET_CAN_EDIT = 'floor/CAN_EDIT'
export const IMAGE_DOWNLOAD_START = 'floor/IMAGE_DOWNLOAD_START'
export const IMAGE_DOWNLOAD_COMPLETE = 'floor/IMAGE_DOWNLOAD_COMPLETE'
export const SET_TEMP_TOKEN = 'floor/SET_TEMP_TOKEN'

export interface Config {
    areaUnit: "sqft" | "sqmt"
    showArea: boolean
    showWork: boolean
    showMeeting: boolean
    showLounge: boolean
}

export interface ListedBy {
    name: string
    email: string
    phone: string
    img?: string
}

export interface Metrics {
    work?: number
    meet?: number
    lounge?: number
}

export interface Location {
    city?: string
    country?: string
    state?: string
    address?: string
    latitude?: number
    longitude?: number
}

export interface GalleryImage {
    src: string
}

export interface FloorState {
    sceneId?: string
    name?: string
    area?: number
    location: Location
    description: string
    locationDescription: string
    logo: string
    listedBy: ListedBy
    config: Config
    metrics: Metrics
    fetchingFloor: boolean
    fetchingMetrics: boolean
    fetchingAssetMetrics: boolean
    showEditModal: boolean
    savingFloor: boolean
    galleryImages: GalleryImage[]
    downloadingImage: boolean
    canEdit: boolean
    tempToken: string
}

export interface ArchilogicTemporalToken {
    authorization: string
    expiresAt: number
}

const initialState: FloorState = {
    sceneId: undefined,
    name: undefined,
    area: undefined, // The area is stored in meters
    location: {
        city: "City",
        state: "State",
        address: "Address Information",
        longitude: -122.398475,
        latitude: 37.792422
    },
    logo: "/img/company-logo.png",
    description: "Provide Space description here.",
    locationDescription: `Provide Location description to display here.`,
    listedBy: {
        name: "First Last Name",
        email: "Email Address",
        phone: "Phone Number",
        img: ""
    },
    metrics: {
        work: undefined,
        meet: undefined,
        lounge: undefined
    },
    config: {
        areaUnit: "sqft",  // The unit to display the area in, by default is sqft but could be sqmt
        showArea: true,
        showMeeting: true,
        showWork: true,
        showLounge: true
    },
    fetchingFloor: false,
    fetchingMetrics: false,
    fetchingAssetMetrics: false,
    showEditModal: false,
    savingFloor: false,
    canEdit: false,
    downloadingImage: false,
    galleryImages: [
        {
            src: '/img/gallery-default.png',
        },
        {
            src: '/img/gallery-default.png',
        },
        {
            src: '/img/gallery-default.png',
        },
        {
            src: '/img/gallery-default.png',
        },
        {
            src: '/img/gallery-default.png',
        },
        {
            src: '/img/gallery-default.png',
        },
        {
            src: '/img/gallery-default.png',
        },
        {
            src: '/img/gallery-default.png',
        },
        {
            src: '/img/gallery-default.png',
        }
    ],
    tempToken: ""

}

const spaces = (state = initialState, action: { type: string, floor: any, metrics: Metrics, sceneId: string, floorData: any, canEdit: boolean, tempToken: any }) => {
    switch (action.type) {
        case SET_SCENE_ID:
            return {
                ...state,
                sceneId: action.sceneId
            }
        case GET_FLOOR:
            return {
                ...state,
                area: action.floor.properties.area,
                fetchingFloor: false
            }
        case GET_FLOOR_CUSTOM_FIELD:
            const {metrics, ...customFieldData} = action.floor.properties.customFields?.listingPage?.data

            if(customFieldData?.canEdit){
                delete customFieldData.canEdit
            }

            if(customFieldData?.tempToken){
                delete customFieldData.tempToken
            }

            return {
                ...state,
                ...customFieldData,
                fetchingFloor: false
            }
        case UPDATE_FLOOR:
            return {
                ...state,
                ...action.floorData
            }
        case UPDATE_METRICS:
            const newMetrics = {
                ...state.metrics,
                meet: action.metrics.meet,
                lounge: action.metrics.lounge
            }

            return {
                ...state,
                metrics: newMetrics,
                fetchingMetrics: false
            }
        case UPDATE_ASSET_METRICS:
            const newAssetMetrics = {
                ...state.metrics,
                work: action.metrics.work
            }

            return {
                ...state,
                metrics: newAssetMetrics,
                fetchingAssetMetrics: false
            }
        case SHOW_EDIT_MODAL:
            return {
                ...state,
                showEditModal: true
            }
        case HIDE_EDIT_MODAL:
            return {
                ...state,
                showEditModal: false
            }
        case FETCHING_FLOOR:
            return {
                ...state,
                fetchingFloor: true
            }
        case SAVING_FLOOR:
            return {
                ...state,
                savingFloor: true
            }
        case SAVING_FLOOR_COMPLETE:
            return {
                ...state,
                savingFloor: false
            }
        case FETCHING_METRICS:
            return {
                ...state,
                fetchingMetrics: true
            }
        case FETCHING_ASSET_METRICS:
            return {
                ...state,
                fetchingAssetMetrics: true
            }
        case SET_CAN_EDIT:
            return {
                ...state,
                canEdit: action.canEdit
            }
        case IMAGE_DOWNLOAD_START:
            return {
                ...state,
                downloadingImage: true
            }
        case IMAGE_DOWNLOAD_COMPLETE:
            return {
                ...state,
                downloadingImage: false
            }
        case SET_TEMP_TOKEN:            
            return {
                ...state,
                tempToken: action.tempToken
            }
        default:
            return state
    }
}

export const receiveTempToken = (data: any) => {
    return { type: SET_TEMP_TOKEN, tempToken: data }
}

export const receiveFloor = (data: any) => {
    return { type: GET_FLOOR, floor: data }
}
export const receiveFloorCustomField = (data: any) => {
    return { type: GET_FLOOR_CUSTOM_FIELD, floor: data }
}

export const showEditModal = () => {
    return { type: SHOW_EDIT_MODAL }
}

export const hideEditModal = () => {
    return { type: HIDE_EDIT_MODAL }
}

export const setCanEdit = (canEdit: boolean) => {
    return { type: SET_CAN_EDIT, canEdit }
}

export const downloadImage = (fileName: string) => (dispatch: any) => {
    dispatch({ type: IMAGE_DOWNLOAD_START })
    const url = document.location.href;
    const publicUrl = url.replace('&edit', '')
    axios({
        url: `${process.env.REACT_APP_BACKEND_URL}/download?url=${encodeURI(publicUrl.replace('edit', ''))}`,
        method: 'GET',
        responseType: 'blob',
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
    }).catch(error => {
        message.error("Something went wrong downloading the flyer")
    }).finally(() => {
        dispatch({ type: IMAGE_DOWNLOAD_COMPLETE })
    })
}


const onReceiveFloorSpaces = (data: any) => (dispatch: any) => {
    const meetCount = data.features.filter((space: any) => space.properties.program === 'meet').length
    const loungeCount = data.features.filter((space: any) => space.properties.program === 'common').length
    const metrics: Metrics = {
        meet: meetCount,
        lounge: loungeCount
    }

    dispatch({ type: UPDATE_METRICS, metrics })
}

const onReceiveFloorAssets = (data: any) => (dispatch: any) => {
    const workStationsCount = data.features.filter((asset: any) => asset.properties.tags.includes("task chair")).length
    const metrics: Metrics = {
        work: workStationsCount
    }

    dispatch({ type: UPDATE_ASSET_METRICS, metrics })
}

export const fetchFloor = (id: string) => (dispatch: any) => {
    
    dispatch({ type: FETCHING_FLOOR })
    return axios.get(`/v2/floor/${id}`,
    ).then(response => {
        dispatch(receiveFloor(response.data))
    }).catch(error => {
        console.log(error)
    })
}

export const calculateFloorMetrics = (id: string) => (dispatch: any) => {
    dispatch({ type: FETCHING_METRICS })
    return axios.get(`/v2/space?floorId=${id}`).then(response => {
        dispatch(onReceiveFloorSpaces(response.data))
    }).catch(error => {
        console.log(error)
    })
}

export const calculateAssetMetrics = (id: string) => (dispatch: any) => {
    dispatch({ type: FETCHING_ASSET_METRICS })
    return axios.get(`/v2/asset?floorId=${id}`).then(response => {
        dispatch(onReceiveFloorAssets(response.data))
    }).catch(error => {
        console.log(error)
    })
}

export const setSceneId = (sceneId: string) => (dispatch: any) => {
    dispatch({ type: SET_SCENE_ID, sceneId })
    dispatch(fetchFloor(sceneId))
    dispatch(fetchFloorCustomFields(sceneId))
    dispatch(calculateFloorMetrics(sceneId))
    dispatch(calculateAssetMetrics(sceneId))
}

export const updateFloorData = (floorData: any) => (dispatch: any) => {
    dispatch({ type: UPDATE_FLOOR, floorData })
    dispatch(persistFloorData())
}

export const getTempToken = () => (dispatch: any) => {
    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/temp-token`)
    .then(response => {
        window.sessionStorage.setItem('tempToken',response.data.authorization)
        dispatch(receiveTempToken(response.data))
    }).catch(error => {
        console.log(error)
    })
}
// Custom fields

export const persistFloorData = () => (dispatch: any, getStore: any) => {
    dispatch({ type: SAVING_FLOOR })
    const floorData: FloorState = getStore().floor
    const { sceneId, area, fetchingFloor, fetchingMetrics, showEditModal, savingFloor, canEdit, downloadingImage, tempToken, ...putData } = floorData
    axios.put(`/v2/floor/${floorData.sceneId}/custom-field/properties.customFields.listingPage`, { data: putData }).then((resp: any) => {
        message.success('Floor saved');
    }).catch((err: any) => {
        message.error('Something wen wrong');

    }).finally(() => {
        dispatch({ type: SAVING_FLOOR_COMPLETE })
    })
}


export const fetchFloorCustomFields = (id: string) => (dispatch: any) => { 
    dispatch({ type: FETCHING_FLOOR })
    return axios.get(`/v2/floor/${id}/custom-field`,
    ).then(response => {        
        dispatch(receiveFloorCustomField(response.data))
    }).catch(error => {
        console.log(error)
    })
}

export default spaces