import { RootState } from 'config/store';
import React, { useEffect, useLayoutEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { calculateFloorMetrics, fetchFloor, getTempToken, setCanEdit, setSceneId } from 'reducers/floor';
import './App.css';
import LandingLayout from './components/layout/Layout';

type Props = ConnectedProps<typeof connector>

const App = (props: Props) => {

  useLayoutEffect(() => {
    props.getTempToken()
  }, [])

  useEffect(() => {
    if (!props.tempToken) {
      return
    }

    const urlParams = new URLSearchParams(window.location.search);
    const sceneId = urlParams.get('sceneId');
    const canEdit = urlParams.has('edit')

    if (sceneId) {
      props.setSceneId(sceneId)
    }

    props.setCanEdit(canEdit)

  }, [props.tempToken])

  return (
    <div className="content-wrapper">
      <LandingLayout sceneId={props.sceneId!} />
    </div>
  );
}

const mapState = (state: RootState) => ({
  sceneId: state.floor.sceneId,
  tempToken: state.floor.tempToken
})

const mapDispatch = {
  fetchFloor,
  calculateFloorMetrics,
  setSceneId,
  setCanEdit,
  getTempToken
}

const connector = connect(mapState, mapDispatch)
export default connector(App);
