import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Row, Col } from 'antd'
import MapboxGLMap from './MapBoxGLMap';
import { RootState } from 'config/store';
import "./Location.scss"

type Props = ConnectedProps<typeof connector>

const Location = (props: Props) => {

    return (
        <Row className="location-container" gutter={[13, 13]}>
            <Col className="" xs={24} sm={24} md={7} lg={7} xl={7}>
                <div className="location-information">
                    <div className="location">
                        <div className="title">Location</div>
                        <div className="city">{`${props.location.city}${props.location.country === 'United States' ? ', ' + props.location.state : ''}`}</div>
                        <div className="guide">
                           {props.locationDescription}
                        </div>
                    </div>
                </div>
            </Col>
            <Col xs={24} sm={24} md={17} lg={17} xl={17}>
                <div className="map-container" id="map-container">
                    <MapboxGLMap latitude={props.location.latitude} longitude={props.location.longitude} />
                </div>
            </Col>
        </Row>
    )
}

const mapState = (state: RootState) => ({
    location: state.floor.location,
    locationDescription: state.floor.locationDescription
})

const mapDispatch = {
}

const connector = connect(mapState, mapDispatch)
export default connector(Location);