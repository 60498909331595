

import React, { useEffect, useState } from 'react';
import { RootState } from 'config/store';
import { connect, ConnectedProps } from 'react-redux';
import { Layout, Row, Col, Avatar, Select } from 'antd'
import "./Layout.css"
import NavHeader from 'components/header/Header';
import Banner from 'components/banner/Banner';
import About, { UserIconSvg } from 'components/about/About';
import ImageGallery from 'components/image-gallery/ImageGallery';
import Location from 'components/location/Location';
import EditModal from 'components/edit-modal/EditModal';
import { hideEditModal } from 'reducers/floor'
import classNames from 'classnames'
import { useMediaQuery } from 'react-responsive'
import Icon, { UserOutlined } from '@ant-design/icons';
const { Header, Footer, Content } = Layout;
const { Option } = Select;

interface LandingLayoutProps {
    sceneId: string
}
type Props = LandingLayoutProps & ConnectedProps<typeof connector>

const LandingLayout = (props: Props) => {

    const [showFooter, setShowFooter] = useState<boolean>(false)
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-device-width: 1224px)'
    })
    return (
        <Layout>
            <Header className="header" style={{ position: 'fixed', zIndex: 100, width: '100%' }} >
                <NavHeader />
            </Header>
            <Content className="content">
                <Banner sceneId={props.sceneId} />
                <About contactSectionVisible={(isVisible: boolean) => { setShowFooter(!isVisible) }} />
                <ImageGallery />
                <Location />
            </Content>
            <Footer className="footer" >
                <Row>
                    <Col span={24}>
                        <div className="powered-by" style={{ height: isDesktopOrLaptop ? ' calc(215px + 104px)' : '215px' }}>
                            <div className="title">Powered by</div>
                            <div className="archi-logo" />
                            <div className="copyright">&copy; 2010 - 2020 &nbsp;&nbsp; Privacy - Terms</div>

                        </div>
                    </Col>
                    {isDesktopOrLaptop &&
                        <Col span={24}>
                            <Row className={classNames("fixed-footer", { 'visible': showFooter, 'hidden': !showFooter })} style={{ position: 'fixed', zIndex: 100, width: '100%', bottom: 0, left: 0 }}>
                                <Col span={24}>
                                    <Avatar size={80} src={props.listedBy.img} className="footer-avatar" icon={<Icon component={() => <UserIconSvg height={50} width={70} />} />} style={{ color: "#000000" }} />
                                    <Row className="listed-by-info">
                                        <Col span={24}>
                                            <div>
                                                Interested in this space? Let's Talk.
                                                </div>
                                        </Col>
                                        <Col span={24}>
                                            <div className="name">
                                                {props.listedBy.name}
                                            </div>
                                            <div className="listed-contact-info">
                                                Email: <a href={`mailto:${props.listedBy.email}`}>{props.listedBy.email}</a> / Phone: <a href={`tel:${props.listedBy.phone}`}>{props.listedBy.phone}</a>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    }
                </Row>
            </Footer>
            <EditModal
                visible={props.showEditModal}
                handleCancel={() => props.hideEditModal()}
            />
        </Layout>
    )
}
const mapState = (state: RootState) => ({
    showEditModal: state.floor.showEditModal,
    listedBy: state.floor.listedBy
})

const mapDispatch = {
    hideEditModal
}

const connector = connect(mapState, mapDispatch)
export default connector(LandingLayout);

