import { createStore, applyMiddleware } from 'redux'
import { combineReducers } from 'redux'
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import floor, { FloorState } from 'reducers/floor'

export interface RootState {
    floor: FloorState
}

declare var window: any
const reducers = combineReducers({ floor })
export const store = createStore(
    reducers,
    composeWithDevTools(
        applyMiddleware(thunk)
    )
)
