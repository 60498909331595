import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { Row, Col, Avatar } from 'antd'
import "./About.scss"
import { RootState } from 'config/store';
import { connect, ConnectedProps } from 'react-redux';
import Icon, { UserOutlined } from '@ant-design/icons';
import { ReactComponent as AboutSVG } from './about.svg';

interface AboutProps {
    contactSectionVisible: (isVisible: boolean) => {}
}

type Props = AboutProps & ConnectedProps<typeof connector>

export interface UserIconSvgProps {
    height: number
    width: number
}

export const UserIconSvg = (props: UserIconSvgProps): any => <Icon component={AboutSVG}></Icon>

const About = (props: Props) => {
    let contactInfoSection = useRef(null)

    useEffect(() => {
        window.addEventListener('scroll', checkIfContactSectionIsVisible)
    }, [])


    const areaUnitToStr = {
        sqft: "Square Feet",
        sqmt: "Square Meters"
    }


    const MetricBox = (props: { value: number | undefined, label: string, loading: boolean }) => {
        const valueStr = props.value ? props.loading ? "calculating" : props.value : '-'
        return (
            <div className="metric">
                <div className="metric-value">{valueStr}</div>
                <div className="metric-title">{props.label}</div>
            </div>
        )
    }

    const checkIfContactSectionIsVisible = () => {
        if (contactInfoSection && contactInfoSection.current) {
            const isVisible = isInViewport(contactInfoSection.current)
            props.contactSectionVisible(isVisible)
        }
    }

    var isInViewport = (elem: Element | null): boolean => {

        if (elem === null) {
            return false
        }

        var distance = elem.getBoundingClientRect();
        return (
            distance.top >= 0 &&
            distance.left >= 0 &&
            distance.bottom <= (window.innerHeight + 100 || document.documentElement.clientHeight + 100) &&
            distance.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    };

    const getAreaValue = (area: number | undefined, areaUnit: string) => {
        if(area){
            return areaUnit === 'sqft' ? Math.round(area * 10.764) : Math.round(area)
        }
    }

    return (
        <Row className="about-container">
            <Col span={24}>
                <div className="about-header">
                    <div>About the Space</div>
                </div>
            </Col>
            <Col span={24}>
                <Row justify="center" gutter={25}>
                    {props.config.showArea &&
                        <Col>
                            <MetricBox label={areaUnitToStr[props.areaUnit]} value={getAreaValue(props.area, props.areaUnit)} loading={props.loadingArea} />
                        </Col>
                    }

                    {props.config.showWork &&

                        <Col>
                            <MetricBox label="Workstations" value={props.work!} loading={props.loadingAssetMetric} />
                        </Col>
                    }

                    {props.config.showMeeting &&

                        <Col>
                            <MetricBox label="Meeting Rooms" value={props.meet!} loading={props.loading} />
                        </Col>
                    }

                    {props.config.showLounge &&

                        <Col>
                            <MetricBox label="Lounges" value={props.lounge!} loading={props.loading} />
                        </Col>
                    }
                </Row>
            </Col>
            <Col span={24}>
                <Row>
                    <Col xs={24} sm={24} md={15} lg={15} xl={15}>
                        <div className="description">
                            {props.description}
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={9} lg={9} xl={9}>
                        <Row className="contact-info" >
                            <Col span={8}>
                                <div className="avatar" ref={contactInfoSection}>
                                    <Avatar size={100} src={props.listedBy.img} icon={<Icon component={AboutSVG}/>} />
                                </div>
                            </Col>
                            <Col span={16}>
                                <div className="info">
                                    <p>Listed by</p>
                                    <p>{props.listedBy.name}</p>
                                    <p><a href={`mailto:${props.listedBy.email}`}>{props.listedBy.email}</a></p>
                                    <p><a href={`tel:${props.listedBy.phone}`}>{props.listedBy.phone}</a></p>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>

        </Row>
    )
}

const mapState = (state: RootState) => ({
    description: state.floor.description,
    areaUnit: state.floor.config.areaUnit,
    area: state.floor.area,
    work: state.floor.metrics.work,
    meet: state.floor.metrics.meet,
    lounge: state.floor.metrics.lounge,
    loading: state.floor.fetchingMetrics,
    loadingAssetMetric: state.floor.fetchingAssetMetrics,
    loadingArea: state.floor.fetchingFloor,
    listedBy: state.floor.listedBy,
    config: state.floor.config
})

const mapDispatch = {
}

const connector = connect(mapState, mapDispatch)
export default connector(About);