import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd'
import { connect, ConnectedProps } from 'react-redux';
import "./Banner.scss"
import { RootState } from 'config/store';

interface BannerProps {
    sceneId: string
}
type Props = BannerProps & ConnectedProps<typeof connector>

const Banner = (props: Props) => {

    return (
        <Row>
            <Col className="banner-container" xs={24} md={24} lg={24} >
                <div className="hero" >
                    <div className="city">{`${props.location.city}${props.location.country === 'United States' ? ', ' + props.location.state : ''}`}</div>
                    <div className="address">{props.location.address}</div>
                    <div className="floor-name">{props.floor.name}</div>
                </div>
            </Col>
            <Col xs={24} md={24} lg={24}>
                <div className="scene">
                    {props.sceneId &&
                        <iframe className="archilogicViewerIframe" key={props.sceneId} src={`https://viewer.archilogic.com/?sceneId=${props.sceneId}&s=m3dffhs1p2sxt0strmrd`}></iframe>
                    }
                </div>
            </Col>
        </Row>
    )
}

const mapState = (state: RootState) => ({
    floor: state.floor,
    location: state.floor.location
})

const mapDispatch = {

}

const connector = connect(mapState, mapDispatch)
export default connector(Banner);
