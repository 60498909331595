import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, List, Avatar } from 'antd'
import "./ImageGallery.scss"
import { useMediaQuery } from 'react-responsive';
import Icon, { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'config/store';
import { GalleryImage } from 'reducers/floor';
import { ReactComponent as ArrowSVG } from './arrow.svg';


const ImageGallery = (props: ConnectedProps<typeof connector>) => {

  const { galleryImages } = props;

  const [activeImage, setActiveImage] = useState<string | null>(null)


  useEffect(() => {
    window.addEventListener('resize', handleResize)
  }, [])


  useEffect(() => {
    if (galleryImages.length > 0) {
      const secureImages: GalleryImage[] = galleryImages.map((img) => {
        img.src = img.src.replace(/http:\/\//, 'https://')
        return img
      })
      setActiveImage(secureImages[0].src)
    }
  }, [galleryImages])

  useEffect(() => {
    handleResize()
  }, [activeImage])

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 762px)' })

  const scroll = (offset: number) => {
    const galleryRef = document.getElementById("scrollGallery");
    galleryRef!.scrollTop = galleryRef!.scrollTop + offset;
  }

  const handleResize = () => {
    const v = document.getElementById('viewport')
    const g = document.getElementById('scrollGallery')

    if (g && v) {
      g.style.height = `${v.getBoundingClientRect().height}px`
    }
  }

  return (
    isTabletOrMobile ? (
      <Row gutter={[15, 0]} justify="center" align="middle" >
        <Col xs={24} sm={24} md={24} lg={4} xl={4}>
          <div className="gallery-container-mobile">
            <div className="gallery-mobile">
              {galleryImages.map(item =>
                <div className="mobile-gallery-content" style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Avatar
                    style={{ display: 'inline-block', verticalAlign: 'middle' }}
                    shape="square"
                    src={item.src}
                    className={`gallery-preview-img ${(activeImage === item.src) ? "active-img" : ""}`}
                  />
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>
    ) : (
        <Row gutter={[15, 0]} className="gallery-container" justify="space-around" align="middle">
          <Col className="" xs={20} >
            <div className="viewport" id="viewport">
              {activeImage &&
                <img src={activeImage} id="image" onLoad={handleResize} />
              }
            </div>
          </Col>
          <Col xs={4}>
            <div className="scrollBtn scrollTop" onClick={() => scroll(-200)} ><Icon component={ArrowSVG}  style={{transform: "rotate(180deg)", paddingTop: '5px'}}/></div>
            <div className="gallery" id={"scrollGallery"}>
              <List
                dataSource={galleryImages}
                itemLayout="vertical"
                renderItem={item => (
                  <List.Item onClick={() => setActiveImage(item.src)} >
                    <Avatar shape="square" src={item.src} className={`gallery-preview-img ${(activeImage === item.src) ? "active-img" : ""}`} />
                    {/* <img src={item.src} className={`gallery-preview-img ${(activeImage === item.src) ? "active-img" : ""}`} onClick={ () => setActiveImage(item.src)} /> */}
                  </List.Item>
                )}

              />
            </div>
            <div className="scrollBtn scrollBottom" onClick={() => scroll(200)} ><Icon component={ArrowSVG} /></div>
            <Icon  />
          </Col>
        </Row>
      )
  )
}



const mapState = (state: RootState) => ({
  galleryImages: state.floor.galleryImages
})

const mapDispatch = {
}

const connector = connect(mapState, mapDispatch)
export default connector(ImageGallery);