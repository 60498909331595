import React, { useEffect, useState } from 'react';
import { Row, Col, Modal, Form, Input, Button, Divider, Checkbox, Radio, Avatar, Upload, message, AutoComplete } from 'antd'
import { connect, ConnectedProps } from 'react-redux';
import Geocode from "react-geocode";
import { RootState } from 'config/store';
import { setSceneId, updateFloorData, Config, ListedBy, Location, GalleryImage } from 'reducers/floor'
import './EditModal.css'
import axios from 'axios';
import mime from 'mime-types'
import { PlusOutlined } from '@ant-design/icons';
const { Option } = AutoComplete;

interface EditModalProps {
    visible: boolean
    handleCancel: () => void
}

type Props = EditModalProps & ConnectedProps<typeof connector>

const EditModal = (props: Props) => {
    Geocode.setApiKey(process.env.REACT_APP_GEOCODE_API_KEY);

    const [form] = Form.useForm();
    const [sceneId, setSceneId] = useState<string>("")
    const [formValues, setFormValues] = useState({})
    const [uploadUrl, setUploadUrl] = useState<string>("")
    const [addressResults, setAddressResults] = useState([])
    const [location, setLocation] = useState<Location>()
    const [contentType, setContentType] = useState<string>("")
    const [profilePictureUrl, setProfilePictureUrl] = useState<string | null>(null)
    const [tempImageURL, setTempImageURL] = useState<string | null>(null)
    const [logoUrl, setLogoUrl] = useState<string | null>(null)
    const [uploading, setUploading] = useState<boolean>(false)
    const [galleryImages, setGalleryImages] = useState<GalleryImage[]>([])

    useEffect(() => {
        if (!props.sceneId) {
            return
        }
        setSceneId(props.sceneId)
        setTempImageURL(null)
        setProfilePictureUrl(null)
    }, [props.sceneId])

    // Reload the form values when a floor is fetched
    useEffect(() => {
        const spatialInsightsData = []

        if (props.config.showArea) {
            spatialInsightsData.push('area')
        }
        if (props.config.showLounge) {
            spatialInsightsData.push('common')
        }
        if (props.config.showMeeting) {
            spatialInsightsData.push('meeting')
        }
        if (props.config.showWork) {
            spatialInsightsData.push('work')
        }

        const values = {
            name: props.floorName,
            description: props.description,
            locationDescription: props.locationDescription,
            spatialInsights: spatialInsightsData,
            areaUnit: props.config.areaUnit,
            listedByName: props.listedBy.name,
            email: props.listedBy.email,
            phone: props.listedBy.phone,
            address: props.location.address
        }

        setProfilePictureUrl(props.listedBy.img!)
        setLogoUrl(props.logo)
        setGalleryImages(props.galleryImages)

        setFormValues(values)
    }, [props.fetchingFloor])


    useEffect(() => {
        form.resetFields()
    }, [formValues])

    const handleOk = () => {
        form.submit()
    }

    const handleCancel = () => {
        props.handleCancel()
    }


    const getUploadUrl = async (file: any) => {
        console.log(file)
        const extension = mime.extension(file.type)

        await axios.get(`https://microservices.archilogic.com/storage/get-upload-url?fileExtension=${extension}`, {
            withCredentials: true
        }).then((response) => {
            console.log(response)
            const url = response.data.result.uploadUrl
            setUploadUrl(url)
            setContentType(response.data.result.contentType)
            setTempImageURL(response.data.result.fileUrlAfterUpload)
        }).catch((error) => {

        }).finally(() => {
            // setUploadUrl("https://www.mocky.io/v2/5cc8019d300000980a055e76")
        })

        return true
    }



    const handleOnFinish = (values: any) => {
        const spatialInsightsData = values.spatialInsights

        const spatialInsightsConfig: Config = {
            showWork: spatialInsightsData.includes('work'),
            showMeeting: spatialInsightsData.includes('meeting'),
            showArea: spatialInsightsData.includes('area'),
            showLounge: spatialInsightsData.includes('common'),
            areaUnit: values.areaUnit
        }

        const listedBy: ListedBy = {
            name: values.listedByName,
            email: values.email,
            phone: values.phone,
            img: profilePictureUrl !== null ? profilePictureUrl : props.listedBy.img
        }

        const { spatialInsights, areaUnit, ...floorData } = values

        floorData.config = { ...floorData.config, ...spatialInsightsConfig }
        floorData.listedBy = { ...floorData.listedBy, ...listedBy }
        if (location) {
            floorData.location = { ...floorData.location, ...location }
        }


        if (logoUrl) {
            floorData.logo = logoUrl
        }

        floorData.galleryImages = galleryImages;


        props.updateFloorData(floorData)
        props.handleCancel() // to close the modal
    }

    const searchAddress = (value: string) => {
        Geocode.fromAddress(value).then(
            (response: any) => {
                setAddressResults(response.results)
            },
            (error: any) => {
                console.log(error);
            })
    }

    const onSelectAddress = (value: string) => {
        const selectedAddress: any = addressResults.find((result: any) => result.formatted_address === value)
        if (!selectedAddress) {
            return
        }

        const city = selectedAddress['address_components'].find((address: any) => address.types.includes("locality"))
        const country = selectedAddress['address_components'].find((address: any) => address.types.includes("country"))
        const state = selectedAddress['address_components'].find((address: any) => address.types.includes("administrative_area_level_1"))
        const streetNumber = selectedAddress['address_components'].find((address: any) => address.types.includes("street_number"))
        const route = selectedAddress['address_components'].find((address: any) => address.types.includes("route"))

        const location: Location = {
            city: city.long_name,
            country: country.long_name,
            state: state.short_name,
            address: `${streetNumber ? streetNumber.short_name : ''} ${route.short_name}`,
            longitude: selectedAddress.geometry.location.lng,
            latitude: selectedAddress.geometry.location.lat
        }

        setLocation(location)
    }

    const addressResultChildren = addressResults.map((result: any) => {
        return (
            <Option key={result.place_id} value={result.formatted_address}>
                {result.formatted_address}
            </Option>
        )
    });

    return (
        <Modal
            title={<div className="modal-title">Edit</div>}
            visible={props.visible}
            onOk={handleOk}
            onCancel={handleCancel}
            className="edit-modal"
            footer={[
                <Button key="back" onClick={handleCancel}>
                    Clear
                </Button>,
                <Button key="submit" type="primary" loading={props.savingFloor} onClick={handleOk}>
                    Save
                </Button>,
            ]}
        >
            <Row className="find-model" gutter={[16, 4]}>
                <Col span={24}>
                    <label>Archilogic Scene ID</label>
                </Col>
                <Col span={16}>
                    <Input placeholder="Scene ID" value={sceneId} onChange={(e: any) => setSceneId(e.target.value)} />
                </Col>
                <Col span={8}>
                    <Button onClick={() => props.setSceneId(sceneId)} loading={props.fetchingFloor}>Find Model </Button>
                </Col>
            </Row>
            <Divider />

            <Form
                layout="vertical"
                form={form}
                initialValues={formValues}
                onFinish={handleOnFinish}
            >
                <Row>
                    <Col span={24}>
                        <div className="title">Property Information</div>
                    </Col>
                    <Col span={24}>
                        <Form.Item name="address" label="Property Address">
                            <AutoComplete onChange={searchAddress} onSelect={onSelectAddress} >
                                {addressResultChildren}
                            </AutoComplete>
                        </Form.Item>
                        <Form.Item name="name" label="Floor Name">
                            <Input placeholder="Second Floor" />
                        </Form.Item>
                        <Form.Item name="spatialInsights" label="Spatial Insights" shouldUpdate>
                            <Checkbox.Group>
                                <Row>
                                    <Col span={12}>
                                        <Checkbox value="area" style={{ lineHeight: '32px' }}>
                                            Floor Area
                                        </Checkbox>
                                    </Col>
                                    <Col span={12}>
                                        <Checkbox value="work" style={{ lineHeight: '32px' }}>
                                            Workstation Count
                                        </Checkbox>
                                    </Col>
                                    <Col span={12}>
                                        <Checkbox value="common" style={{ lineHeight: '32px' }}>
                                            Common Areas Count
                                         </Checkbox>
                                    </Col>
                                    <Col span={12}>
                                        <Checkbox value="meeting" style={{ lineHeight: '32px' }}>
                                            Meeting Rooms Count
                                        </Checkbox>
                                    </Col>
                                </Row>
                            </Checkbox.Group>
                        </Form.Item>
                        <Form.Item name="areaUnit" label="Area Unit" shouldUpdate>
                            <Radio.Group>
                                <Radio value="sqft">Square Feets</Radio>
                                <Radio value="sqmt">Square Meters</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item name="description" label="Property Description">
                            <Input.TextArea rows={4} />
                        </Form.Item>
                        <Form.Item name="locationDescription" label="Location Description">
                            <Input.TextArea rows={4} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <div className="title">Upload Images</div>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Gallery">
                            <Upload
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                listType="picture-card"
                                fileList={galleryImages.map((item, index) => { return { uid: index.toString(), name: item.src, url: item.src, status: 'done', size: 1, type: '' } })}
                                onChange={({ fileList }) => setGalleryImages(fileList.map(item => { return { src: item.url! } }))}
                                // @ts-ignore
                                beforeUpload={getUploadUrl}
                                customRequest={(data: any) => {
                                    setUploading(true)
                                    fetch(uploadUrl, {
                                        method: 'PUT',
                                        body: data.file,
                                        cache: 'no-cache',
                                        credentials: 'omit',
                                        headers: { 'Content-Type': contentType }
                                    }).then(() => {
                                        const gal = galleryImages;
                                        gal.push({ src: tempImageURL! });
                                        setGalleryImages(gal);
                                        data.onSuccess()
                                    }).catch(() => {
                                        data.onError()
                                    }).finally(() => {
                                        setUploading(false)
                                    })
                                }}
                            >
                                {galleryImages.length >= 8 ? null :
                                    <div>
                                        <PlusOutlined />
                                        <div className="ant-upload-text">Upload</div>
                                    </div>
                                }

                            </Upload>


                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <div className="title">Listing Agent Information</div>
                    </Col>
                    <Col span={24}>
                        <Row>
                            <Col span={10} className="profile-picture-upload">
                                <Avatar size={150} src={profilePictureUrl !== null ? profilePictureUrl : props.listedBy.img} />
                                <Upload
                                    name="file"
                                    action={uploadUrl}
                                    method="PUT"
                                    headers={{
                                        'Content-Type': contentType
                                    }}
                                    className="upload-profile-picture-button"
                                    // @ts-ignore
                                    beforeUpload={getUploadUrl}
                                    onChange={(info) => {
                                        if (info.file.status !== 'uploading') {
                                            console.log(info.file, info.fileList);
                                        }
                                        if (info.file.status === 'done') {
                                            message.success(`${info.file.name} file uploaded successfully`);
                                        } else if (info.file.status === 'error') {
                                            message.error(`${info.file.name} file upload failed.`);
                                        }
                                    }}
                                    customRequest={(data: any) => {
                                        setUploading(true)
                                        fetch(uploadUrl, {
                                            method: 'PUT',
                                            body: data.file,
                                            cache: 'no-cache',
                                            credentials: 'omit',
                                            headers: { 'Content-Type': contentType }
                                        }).then(() => {
                                            console.log('profilePictureUrlTemp', tempImageURL)
                                            setProfilePictureUrl(tempImageURL)
                                            data.onSuccess()
                                        }).catch(() => {
                                            data.onError()
                                        }).finally(() => {
                                            setUploading(false)
                                        })
                                    }}
                                >
                                    <Button loading={uploading}>Upload Photo</Button>
                                </Upload>
                            </Col>
                            <Col span={14}>
                                <Row>
                                    <Col span={24}>
                                        <Form.Item name="listedByName" label="Name" style={{ marginBottom: '10px' }}>
                                            <Input placeholder="" />
                                        </Form.Item>
                                        <Form.Item name="email" label="Email" style={{ marginBottom: '10px' }}>
                                            <Input placeholder="" />
                                        </Form.Item>
                                        <Form.Item name="phone" label="Phone" style={{ marginBottom: '10px' }}>
                                            <Input placeholder="" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Row>
                                            <Col span={24}>
                                                <div className="title" style={{ fontSize: '18px', marginBottom: '10px' }}>Company Logo</div>
                                            </Col>
                                            <Col span={24}>
                                                <div className="logo-placeholder">
                                                    <img src={logoUrl !== null ? logoUrl : props.logo} />
                                                </div>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item>

                                                    <Upload
                                                        name="file"
                                                        action={uploadUrl}
                                                        method="PUT"
                                                        headers={{
                                                            'Content-Type': contentType
                                                        }}
                                                        className="upload-logo-button"
                                                        // @ts-ignore
                                                        beforeUpload={getUploadUrl}
                                                        onChange={(info) => {
                                                            if (info.file.status !== 'uploading') {
                                                                console.log(info.file, info.fileList);
                                                            }
                                                            if (info.file.status === 'done') {
                                                                message.success(`${info.file.name} file uploaded successfully`);
                                                            } else if (info.file.status === 'error') {
                                                                message.error(`${info.file.name} file upload failed.`);
                                                            }
                                                        }}
                                                        customRequest={(data: any) => {
                                                            setUploading(true)
                                                            fetch(uploadUrl, {
                                                                method: 'PUT',
                                                                body: data.file,
                                                                cache: 'no-cache',
                                                                credentials: 'omit',
                                                                headers: { 'Content-Type': contentType }
                                                            }).then(() => {
                                                                console.log('profilePictureUrlTemp', tempImageURL)
                                                                setLogoUrl(tempImageURL)
                                                                data.onSuccess()
                                                            }).catch(() => {
                                                                data.onError()
                                                            }).finally(() => {
                                                                setUploading(false)
                                                            })
                                                        }}
                                                    >
                                                        <Button loading={uploading}>Upload Logo</Button>
                                                    </Upload>
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                    </Col>

                </Row>

            </Form>
        </Modal>
    )
}


const mapState = (state: RootState) => ({
    sceneId: state.floor.sceneId,
    fetchingFloor: state.floor.fetchingFloor,
    floorName: state.floor.name,
    description: state.floor.description,
    locationDescription: state.floor.locationDescription,
    listedBy: state.floor.listedBy,
    savingFloor: state.floor.savingFloor,
    config: state.floor.config,
    location: state.floor.location,
    logo: state.floor.logo,
    galleryImages: state.floor.galleryImages
})

const mapDispatch = {
    setSceneId,
    updateFloorData
}

const connector = connect(mapState, mapDispatch)
export default connector(EditModal);
